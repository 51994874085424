<template>
  <div class="sctp-container pad-tb10">
    <div class="mg-b10">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item>购物车</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="sctp-bg-white">
      <template v-if="cartGroup && cartGroup.length === 0">
        <div style="padding: 10%;" v-loading="!isInit">
          <empty image="/images/empty/cart.png" message="购物车什么都没有T.T"></empty>
        </div>
      </template>
      <template v-else>
        <div class="sctp-card" v-loading="!isInit">
          <div class="sctp-card-header">
          </div>
          <div class="sctp-card-body">
            <el-row class="sctp-pad-tb5 sctp-pad-lr15 sctp-bold">
              <el-col :span="1">
                <el-checkbox class="sctp-bold" @change="setAllStatus"
                             :indeterminate="indeterminate"
                             :value="selectAll">全选
                </el-checkbox>
              </el-col>
              <el-col :span="10">
                <div class="sctp-tc">店铺宝贝</div>
              </el-col>
              <el-col :span="4">
                <div class="sctp-tc">单价</div>
              </el-col>
              <el-col :span="4">
                <div class="sctp-tc">数量</div>
              </el-col>
              <el-col :span="4">
                <div class="sctp-tc">小计</div>
              </el-col>
              <el-col :span="1">
                <div class="sctp-tc">操作</div>
              </el-col>
            </el-row>
            <template v-for="(cart,index) in cartGroup">
              <template v-if="index > 0">
                <div style="height: 15px;"></div>
              </template>
              <div :key="cart.shopId" class="sctp-flex sctp-mar-tb10 sctp-flex-aic">
                <el-image
                  class="sctp-mar-r15"
                  style="width: 20px; height: 20px"
                  :src="cart.shopLogo"
                  fit="cover"></el-image>
                <div>店铺：{{ cart.shopName }}</div>
              </div>
              <div class="goods-list">
                <el-row class="sctp-pad-tb15 goods-item flex sctp-flex-aic"
                        v-for="goods in cart.goods"
                        :key="cart.shopId + '-' + goods.productId">
                  <el-col :span="1">
                    <el-checkbox :disabled="!goods.available || goods.stock <= 0"
                                 v-model="goods.selected"></el-checkbox>
                  </el-col>
                  <el-col :span="10">
                    <router-link target="_blank" :to="{
                                2: `/sourcecode/view/${goods.productId}`,
                                4: `/design/view/${goods.productId}`,
                                6: `/techdocs/view/${goods.productId}`
                              }[goods.productType]">
                      <div class="">
                        <div class="sctp-flex">
                          <el-image
                            class="sctp-mar-r15"
                            style="width: 80px; height: 80px"
                            :src="goods.productLogo"
                            fit="cover"></el-image>
                          <div class="sctp-flex-item-1">
                            <!-- <div class="mg-b5">{{-->
                            <!--     {2: '源码', 4: '设计', 6: '文档'}[goods.productType]-->
                            <!--   }}: #{{ goods.productId }}-->
                            <!-- </div>-->
                            <div class="mg-b5">{{ goods.productName }}</div>
                            <div class="mg-b5">商品编号：#{{ goods.productId }}</div>
                            <div>商品类型：{{ goods.typeName }} - {{ goods.subTypeName }}</div>
                            <div class="fz-18 sctp-bold fc-red" v-if="!goods.available">
                              商品已下架或已删除
                            </div>
                          </div>
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                  <el-col :span="4">
                    <div style="" class="sctp-tc">¥{{ goods.productPrice }}</div>
                  </el-col>
                  <el-col :span="4">
                    <div style="" class="sctp-tc">
                      <el-input-number @change="updateGoodsNumber(arguments,goods)" size="small"
                                       :max="goods.stock" :min="1"
                                       :disabled="!goods.available || goods.stock <= 0"
                                       v-model.number="goods.productAmount"
                                       :step-strictly="true"></el-input-number>
                    </div>
                    <div v-if="goods.stock <= 0" class="sctp-tc fc-red fz-18">
                      暂无库存
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div style="" class="sctp-color-main sctp-tc">¥{{
                        goods.productPrice.mul(goods.productAmount)
                      }}
                    </div>
                  </el-col>
                  <el-col :span="1">
                    <div style="">
                      <el-button size="mini" @click="deleteCart(goods)" type="danger"
                                 icon="el-icon-delete"
                                 circle></el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </template>
          </div>
          <div class="sctp-flex sctp-pad-tb15 sctp-mar-tb15 sctp-flex-sb">
            <div>
              <el-button :disabled="!cartGroup || cartGroup.length === 0" @click="onClearCartClick">
                清空购物车
              </el-button>
            </div>
            <div class="sctp-flex sctp-flex-aic fz-18">
              <div class="sctp-mar-r20">共选择 <span
                class="sctp-font-18 sctp-color-main">{{ selectCount || 0 }}</span> 件商品
              </div>
              <div class="sctp-mar-r20">总计：<span>¥{{ totalPrice }}</span></div>
              <el-button @click="settlement" type="primary">去结算</el-button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {cart} from "../../apis";

const config = require('../../resources/js/config');
export default {
  name: 'myCart',
  components: {
    empty: () => import('@CMP/common/EmptyData.vue'),
  },
  data() {
    return {
      isInit: false,
      cartGroup: null,
    }
  },
  methods: {
    settlement() {
      if (!this.selectAll && !this.indeterminate) {
        this.$message.warning('请选择需要结算的商品');
        return false;
      }
      const selectedGoods = [];
      if (this.cartGroup) {
        this.cartGroup.forEach(cart => {
          if (cart.goods && cart.goods.length > 0) {
            cart.goods.forEach(goods => {
              if (goods.selected) {
                selectedGoods.push(goods)
              }
            })
          }
        })
      }
      if (selectedGoods.length === 0) {
        this.$message.warning('请选择需要结算的商品');
        return false
      }
      const goBuy = () => {
        let cartIds = selectedGoods.map(item => item.id);
        cart.cartList({
          cartIds: cartIds
        }).then(res => {
          let {retdata} = res;
          this.$router.push({
            path: '/order/buy/12',
            query: {
              cartGroup: JSON.stringify(retdata),
            }
          });
        })
      };
      goBuy();
    },
    getCarts() {
      return cart.getCarts({
        userId: this.user.userId
      }).then(res => {
        let {retdata: cartGroup = []} = res;
        this.cartGroup = cartGroup;
      })
    },
    updateGoodsNumber(arr, goods) {
      let [current, old] = arr;
      current = Math.round(current || 1);
      if (current !== old) {
        cart.updateGoodsNumber({
          cartId: goods.id,
          amount: current
        }).catch(res => {
          goods.productAmount = old;
        });
      }
    },
    deleteCart(goods) {
      cart.delCart({
        cartId: goods.id
      }).then(res => {
        this.$message.success('删除成功');
        this.$Bus.$emit('cartCountChange')
        this.getCarts();
      })
    },
    setAllStatus(flag) {
      if (this.cartGroup) {
        this.cartGroup.forEach(cart => {
          if (cart.goods && cart.goods.length > 0) {
            cart.goods.filter(item => item.available && item.stock > 0).forEach(goods => {
              this.$set(goods, 'selected', !!flag);
            })
          }
        })
      }
    },
    onClearCartClick() {
      this.$confirm('此操作会清空购物车，无法撤销，是否继续?', '提示', {
        confirmButtonText: '清空',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doClearCart()
      })
    },
    doClearCart() {
      return cart.clearCart({
        userId: this.user.userId
      }).then(res => {
        this.$message.success('购物车清空成功!');
        this.getCarts();
        this.$Bus.$emit('cartCountChange');
      })
    }
  },
  computed: {
    /**
     * 商品总价格
     * @returns {number}
     */
    totalPrice() {
      let totalPrice = 0;
      if (this.cartGroup) {
        this.cartGroup.forEach(cart => {
          if (cart.goods && cart.goods.length > 0) {
            cart.goods.forEach(goods => {
              if (goods.selected) {
                totalPrice += goods.productPrice * goods.productAmount
              }
            })
          }
        })
      }
      return totalPrice
    },
    selectCount() {
      let count = 0;
      if (this.cartGroup) {
        this.cartGroup.forEach(cart => {
          if (cart.goods && cart.goods.length > 0) {
            cart.goods.forEach(goods => {
              if (goods.selected) {
                count++
              }
            })
          }
        })
      }
      return count
    },
    selectAll() {
      const flag = false;
      if (this.cartGroup) {
        return this.cartGroup.every(cart => {
          if (cart.goods && cart.goods.length > 0) {
            return cart.goods.every(goods => {
              return goods.selected
            })
          }
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        const flag = false;
        if (this.cartGroup) {
          return this.cartGroup.some(cart => {
            if (cart.goods && cart.goods.length > 0) {
              return cart.goods.some(goods => {
                return goods.selected
              })
            }
          })
        }
        return flag
      }
    }
  },
  beforeMount() {
    this.getCarts().finally(() => {
      this.isInit = true;
    })
  }
}
</script>

<style scoped lang="scss">
.sctp-card {
  padding: 15px;
}

.el-input-number {
  width: 110px;
}

.sctp-card .sctp-card-header .title {
  margin: 15px 0;
  border-left: 3px solid #F90;
  padding-left: 15px;
  color: #F90;
}

.goods-list {
  border: #dedede solid 1px;
  padding: 0 15px;
}

.goods-item + .goods-item {
  border-top: #E7E7E7 solid 1px;
}
</style>
